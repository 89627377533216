import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ALertNotification,
  Input,
  ModalAlamatTambah,
  ModalOrderAlamat,
  SideBar,
} from "../../components";
import { getJamList } from "../../config/redux/action/settingReducer";
import { Order } from "../../utils";
import moment from "moment";
import { createOrder } from "../../config/redux/action/orderAction";
import { getMitraList } from "../../config/redux/action/mitraAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function CreateOrderPage() {
  const { JamList } = useSelector((state) => state.settingReducer);
  const { MitraList } = useSelector((state) => state.mitraReducer);
  const [DataJam, setDataJam] = useState([]);
  const [DataMitra, setDataMitra] = useState([]);
  const [TanggalOrder, setTanggalOrder] = useState(moment().toDate());
  const [Alamat, setAlamat] = useState("");
  const [Position, setPosition] = useState([]);
  const [AlamatKirim, setAlamatKirim] = useState("");
  const [PositionKirim, setPositionKirim] = useState([]);
  const [Note, setNote] = useState("");
  const [Keterangan, setKeterangan] = useState("");
  const [Jam, setJam] = useState("");
  const [Mitra, setMitra] = useState(
    JSON.stringify({
      id: 0,
      alamat: ``,
      longlat: "",
    })
  );
  const [ButtonDisable, setButtonDisable] = useState(false);
  const [JenisOrder, setJenisOrder] = useState("PaketSatuan");
  const [Pesan, setPesan] = useState("");
  const [Update, setUpdate] = useState(0);
  const [ShowAlamat, setShowAlamat] = useState(false);
  const [ShowAlamatTambah, setShowAlamatTambah] = useState(false);
  const [ShowAlamatKirim, setShowAlamatKirim] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => setShowAlert(false);
  const handleCloseAlamat = () => setShowAlamat(false);
  const handleCloseAlamatTambah = () => setShowAlamatTambah(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getJamList({
        url: "/setting/jam",
        type: "JAM_LIST",
      }),
      dispatch
    );

    dispatch(
      getMitraList({
        url: "/mitra",
        type: "MITRA_LIST",
      }),
      dispatch
    );
  }, []);

  useEffect(() => {
    let data = [];

    if (JamList.length > 0) {
      JamList.map((val, key) => {
        if (key === 0) {
          setJam(val.nama);
        }

        data.push({
          id: val.nama,
          name: val.nama,
        });
      });
    }

    return setDataJam(data);
  }, [JamList]);

  useEffect(() => {
    let data = [];

    data.push({
      id: JSON.stringify({
        id: 0,
        alamat: ``,
        longlat: "",
      }),
      name: "Order Konsumen",
    });

    if (MitraList.length > 0) {
      MitraList.map((val, key) => {
        if (key === 0) {
          setMitra({
            id: JSON.stringify({
              id: 0,
              alamat: ``,
              longlat: "",
            }),
            name: "Order Konsumen",
          });
        }

        data.push({
          id: JSON.stringify({
            id: val.id,
            alamat: `${val.nama}\n${val.notelp}\n${val.alamat}`,
            longlat: val.longlat,
          }),
          name: val.nama,
        });
      });
    }
    return setDataMitra(data);
  }, [MitraList]);

  const jenisOrderData = () => {
    let data = [];

    Order.map((val, key) => {
      if (val.value !== "semua" && val.value !== "berlangganan") {
        data.push({
          id: val.value,
          name: val.keterangan,
        });
      }
    });

    return data;
  };

  const simpanOrder = () => {
    if (Alamat === "") {
      setPesan("Alamat belum terisi");
      return setShowAlert(true);
    }

    setButtonDisable(true);

    dispatch(
      createOrder({
        url: "/order/create",
        jenis_order: JenisOrder,
        tanggal_pesan: TanggalOrder,
        jam_kirim: Jam,
        mitra_id: JSON.parse(Mitra).id || 0,
        alamat_detail: Alamat,
        titik_jemput: Position,
        alamat_kirim: AlamatKirim === "" ? Alamat : AlamatKirim,
        titik_kirim: PositionKirim.length > 0 ? PositionKirim : Position,
        note: Note,
        keterangan: Keterangan,
      })
    ).then((result) => {
      if (result.status === 200) {
        setButtonDisable(false);
        window.location.href =
          "/order/detail?no_order=" + result.data.result.no_order;
      }
    });
  };

  return (
    <div>
      <SideBar />
      <div className="content">
        <div className="bg-white px-2 py-1 rounded shadow">
          <div className="d-flex flex-column">
            <div className="fw-bold fs-3 align-self-center mb-3">
              Buat Order Baru
            </div>
            <div className="row px-2">
              <div className=" col-6 col-lg-3">
                <Input
                  judul={"Jenis Order"}
                  data={jenisOrderData()}
                  type={"select"}
                  value={JenisOrder}
                  onChange={(e) => setJenisOrder(e.target.value)}
                />
              </div>
              <div className=" col-6 col-lg-3">
                <Input
                  judul={"Jam Ambil"}
                  data={DataJam}
                  type={"select"}
                  value={Jam}
                  onChange={(e) => setJam(e.target.value)}
                />
              </div>
              <div className=" col-6 col-lg-3">
                <Input
                  judul={"Mitra"}
                  data={DataMitra}
                  type={"select"}
                  value={Mitra}
                  onChange={(e) => {
                    // return console.log(JSON.parse(e.target.value));
                    setAlamat(JSON.parse(e.target.value).alamat || "");
                    setPosition(JSON.parse(e.target.value).longlat || "");
                    setMitra(e.target.value);
                  }}
                />
              </div>
              <div className=" col-6 col-lg-3">
                <Input
                  judul={"Tanggal Order"}
                  type={"reactdatetime"}
                  selected={TanggalOrder}
                  onChange={(val) => {
                    setTanggalOrder(val);
                    console.log(val);
                  }}
                />
              </div>
              <div className="col-6 d-flex flex-column">
                <div className="w-100">
                  <Input
                    judul={"Alamat Penjemputan"}
                    type={"textarea"}
                    value={Alamat}
                    disabled={true}
                    rows={6}
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-sm-3">
                    <button
                      className="btn btn-sm px-4 btn-success text-white shadow-none"
                      onClick={() => setShowAlamat(true)}
                    >
                      Pilih
                    </button>
                  </div>

                  <div className="col-12 col-sm-8 col-lg-6 mt-3 mt-sm-0">
                    <button
                      className="btn btn-sm px-4 btn-primary shadow-none"
                      onClick={() => setShowAlamatTambah(true)}
                    >
                      Tambah ALamat Baru
                    </button>
                  </div>
                </div>
                <div className="w-100">
                  <Input
                    judul={"Alamat Pengiriman"}
                    type={"textarea"}
                    value={AlamatKirim}
                    disabled={true}
                    rows={6}
                  />
                </div>
                <div className="row">
                  <div className="col-12 mb-2">
                    <button
                      className="btn btn-sm px-4 btn-success shadow-none text-white "
                      onClick={() => setShowAlamatKirim(true)}
                    >
                      Pilih
                    </button>
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      marginLeft: 10,
                      fontStyle: "italic",
                    }}
                    className="col-12"
                  >
                    Alamat pengiriman sesuai penjemputan bila tidak diisi
                  </div>
                </div>
              </div>

              <div className="col-6 d-flex flex-column">
                <Input
                  judul={"Note"}
                  type={"textarea"}
                  value={Note}
                  onChange={(e) => setNote(e.target.value)}
                />

                <Input
                  judul={"Keterangan"}
                  type={"textarea"}
                  value={Keterangan}
                  onChange={(e) => setKeterangan(e.target.value)}
                />
              </div>
            </div>
            {ButtonDisable ? (
              <button className="btn btn-danger align-self-end my-3 me-3 w-25">
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              </button>
            ) : (
              <button
                className="btn btn-danger align-self-end my-3 me-3 w-25"
                onClick={() => simpanOrder()}
              >
                Simpan Faktur
              </button>
            )}
          </div>
        </div>
      </div>
      <ModalOrderAlamat
        show={ShowAlamat}
        handleClose={() => handleCloseAlamat()}
        update={(alamat, position) => {
          setAlamat(alamat);
          setPosition(position);
          setMitra(
            JSON.stringify({
              id: 0,
              alamat: ``,
              longlat: "",
            })
          );
        }}
      />

      <ModalOrderAlamat
        show={ShowAlamatKirim}
        handleClose={() => setShowAlamatKirim(false)}
        update={(alamat, position) => {
          setAlamatKirim(alamat);
          setPositionKirim(position);
          setMitra(
            JSON.stringify({
              id: 0,
              alamat: ``,
              longlat: "",
            })
          );
        }}
      />
      <ALertNotification
        show={ShowAlert}
        handleClose={() => handleCloseAlert()}
        pesan={Pesan}
      />
      <ModalAlamatTambah
        show={ShowAlamatTambah}
        handleClose={() => handleCloseAlamatTambah()}
        update={() => {
          handleCloseAlamatTambah();
          setUpdate(Update + 1);
        }}
      />
    </div>
  );
}

export default CreateOrderPage;
